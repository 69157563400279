/* eslint-disable @nx/enforce-module-boundaries */
import { Avatar } from '@mybridge/ui/avatar';
import { useOutsideClick } from '@mybridge/ui/hooks';
import { Box, HStack, Stack } from '@mybridge/ui/layout';
import { useContext, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CommentForm } from 'v4/components/comment/comment-form';
import {
  PostCommentFormContext,
  usePostCommentFormContext,
} from 'v4/components/comment/comment-form/context';
import {
  usePostCommentRowContext,
  PostCommentRowContext,
} from 'v4/components/comment/comment-row/context';
import { PostBoxContext } from 'v4/components/post/context';
import { getUserFullName, getUserProfilePic } from 'v4/lib/commons';

export const PostCommentForm = ({ ...props }) => {
  const { post, refetch, refetchComments } = useContext(PostBoxContext);

  const { comment } = useContext(PostCommentRowContext);
  const { campaignDetails } = useSelector(
    (state) => state.campaignReducer
  );
  const postCommentRowContext = usePostCommentRowContext();

  const { setCommentBoxExapnded, commentBoxExpanded } =
    postCommentRowContext ?? {};

  const refetchAll = () => {
    refetch?.();
    refetchComments?.();
  };

  const postCommentFormContext = usePostCommentFormContext({
    post,
    parent: comment,
    refetch: refetchAll,
    showComments: true,
    level: 0,
  });

  const { hasContent } = postCommentFormContext ?? {};

  const { userProfileInfo, currentProfile: companyDetails } = useSelector(
    (state) => state.userProfile
  );
  const profilePic = getUserProfilePic(userProfileInfo);
  const userFullName = getUserFullName(userProfileInfo);
  const cfRef = useRef();
  useOutsideClick({
    ref: cfRef,
    handler: () => handleFieldBlur(),
  });

  const handleFieldBlur = () => {
    if (!hasContent) {
      setCommentBoxExapnded && setCommentBoxExapnded(false);
    }
  };

  console.log(postCommentFormContext,"campaignDetailscampaignDetails");
  return (
    <PostCommentFormContext.Provider
      value={{
        ...(postCommentFormContext ?? {}),
        setExpanded: setCommentBoxExapnded,
        expanded: commentBoxExpanded,
      }}
    >
      <Box py={2} {...props}>
        <HStack alignItems="flex-start" ref={cfRef}>
          <Stack>
            {props.currentPostCommenter ? (
              <Avatar
                src={
                  props.currentPostCommenter.image
                    ? props.currentPostCommenter.image
                    : props.currentPostCommenter.profile_pic
                }
                name={`${
                  props.currentPostCommenter.name
                    ? props.currentPostCommenter.name
                    : props.currentPostCommenter.display_name
                }`}
              ></Avatar>
            ) : (
              <Avatar
                name={userFullName}
                src={companyDetails ? companyDetails?.image : profilePic}
              />
            )}
          </Stack>
          <CommentForm
            currentPostCommenter={props.currentPostCommenter}
            taggedUser={userFullName}
          />
        </HStack>
      </Box>
    </PostCommentFormContext.Provider>
  );
};
